import '../App.css';
import React from "react";
import logo from "../images/HyperZone_horiz_for-orange-bg.png";
import omnihyperLogo from "../images/OmniHyper_white-logo.png";

function Footer() {
    return (
        <footer>
            <div className={"footer-section"}>
                <div>
                    <h2>Businesses That Trust HyperZone®</h2>
                </div>
                <div></div>
                <div></div>
            </div>
            <div className={"footer"}>
                <div className={"footer-row1"}>
                    <div className={"footer-column-logo"}>
                        <div className={"footer-logo-wrapper"}>
                            <a href="https://hyperzone.omnihyper.com/">
                                <img src={logo} alt={"Hyperzone Logo"} width={"248"}/>
                            </a>
                        </div>
                    </div>
                    <div className={"footer-column-menu"}>
                        <div>
                            <nav className="footer-menu-nav">
                                <ul id="menu-footer-menu" className="">
                                    <li className="et_pb_menu_page_id-242516 menu-item menu-item-type-custom menu-item-object-custom menu-item-242516">
                                        <a target="_blank" rel="noreferrer"
                                           href="https://omnihyper.com/about-us/our-story/">ABOUT US</a></li>
                                    <li className="et_pb_menu_page_id-243085 menu-item menu-item-type-post_type menu-item-object-page menu-item-243808">
                                        <a href="https://hyperzone.omnihyper.com/news-blog/">NEWS &amp; BLOG</a></li>
                                    <li className="et_pb_menu_page_id-242505 menu-item menu-item-type-post_type menu-item-object-page menu-item-242515">
                                        <a href="https://hyperzone.omnihyper.com/contact/">CONTACT</a></li>
                                    <li className="et_pb_menu_page_id-242518 menu-item menu-item-type-custom menu-item-object-custom menu-item-242518">
                                        <a target="_blank" rel="noreferrer"
                                           href="https://omnihyper.com/privacy-policy/">PRIVACY
                                            POLICY</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className={"footer-row2"}>
                    <div className={"footer-divider-container"}>
                        <div className={"footer-divider-wrapper"}>
                            <div className={"footer-divider"}></div>
                        </div>
                    </div>
                </div>
                <div className={"footer-row3"}>
                    <div className={"footer-row3-column1"}>
                        <div className={"footer-text-wrapper"}>
                            <p>IS PROUDLY BROUGHT TO YOU BY&nbsp;
                                <a href="https://omnihyper.com" target="_blank" rel="noreferrer">
                                    <img src={omnihyperLogo} width="81" height="25" alt="OmniHyper logo"
                                         className="wp-image-18 alignnone size-full"/>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className={"footer-row3-column2"}>
                        <div className={"footer-hyperzone-container"}>
                            <p className={"hyperzone-text"}>©HyperZone®</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mobile-free-demo-container">
                <div><a href="#" onClick={() => {
                    window.Calendly.initPopupWidget({
                        url: 'https://calendly.com/omnihyper_pt/hyperzone?hide_gdpr_banner=1&amp;primary_color=ff700f',
                        textColor: '#ffffff',
                        branding: false
                    });
                    return false;
                }} className="curvebtn btn-calendly">
                    FREE DEMO
                </a></div>
            </div>
        </footer>
    )

}

export default Footer;