import '../App.css';
import React, {useRef, useState} from "react";
import {FiChevronLeft, FiChevronRight, FiExternalLink} from "react-icons/fi";
import {Tooltip} from "@mui/material";
import {IoMdInformationCircleOutline} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {isIOS} from "../utils/utils";


function DemandMap({mapImage, keywordOverviewOrganic, competitors, competitorsGmbInfo, gmb, competitorsDfs}) {
    const {t} = useTranslation("common");

    const tableWrapperRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleScroll = (direction) => {
        const scrollAmount = 300; // Adjust this value to control the scroll distance
        const newScrollLeft = direction === 'left'
            ? Math.max(scrollLeft - scrollAmount, 0)
            : scrollLeft + scrollAmount;

        tableWrapperRef.current.scrollTo({
            left: newScrollLeft,
            behavior: 'smooth'
        });
        setScrollLeft(newScrollLeft);
    };

    return (
        <div className="grid-container demand-map">
            <div className="wrapper grid-item4">
                <div className={"title-wrapper"}>
                    <h3 className="title">{t('demandMap.titleDemand')}</h3>
                    <div className={"desktop"}>
                        <Tooltip title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('demandMap.titleDemand')}</p>
                                    <p>{t('demandMap.demandInfo1')}</p>
                                    <p className={"tip-title"}>{t('demandMap.demandInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                    <div className={"tablet-mobile"}>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={6000} title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('demandMap.titleDemand')}</p>
                                    <p>{t('demandMap.demandInfo1')}</p>
                                    <p className={"tip-title"}>{t('demandMap.demandInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                </div>
                {
                    keywordOverviewOrganic.error || !keywordOverviewOrganic.length ?
                        <div>
                            <p>{t('demandMap.demandNoResult')}</p>
                        </div> :
                        <div className={'results demand'}>
                            <div className={"table-wrapper"} ref={tableWrapperRef}>
                                <table cellSpacing="0">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>{keywordOverviewOrganic[0][0]["Keyword"]}</th>
                                        {
                                            keywordOverviewOrganic[1][0]["Keyword"] === "No second keyword" ?
                                                <th></th> :
                                                <th>{keywordOverviewOrganic[1][0]["Keyword"]}</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="highlighted demand-type">{t('demandMap.demandType1')}</td>
                                        <td>{keywordOverviewOrganic[0][0]["Search Volume"]}</td>
                                        {
                                            keywordOverviewOrganic[1][0]["Keyword"] === "No second keyword" ?
                                                <td></td> :
                                                <td>{keywordOverviewOrganic[1][0]["Search Volume"]}</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td className="highlighted demand-type">{t('demandMap.demandType2')}</td>
                                        <td>{keywordOverviewOrganic[2][0]["Search Volume"]}</td>
                                        {
                                            keywordOverviewOrganic[1][0]["Keyword"] === "No second keyword" ?
                                                <td></td> :
                                                <td>{keywordOverviewOrganic[3][0]["Search Volume"]}</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td className="highlighted demand-type">{t('demandMap.demandType3')}</td>
                                        <td>{parseInt(keywordOverviewOrganic[4][0]["Search Volume"]) + parseInt(keywordOverviewOrganic[6][0]["Search Volume"])}</td>
                                        {
                                            keywordOverviewOrganic[1][0]["Keyword"] === "No second keyword" ?
                                                <td></td> :
                                                <td>{parseInt(keywordOverviewOrganic[5][0]["Search Volume"]) + parseInt(keywordOverviewOrganic[7][0]["Search Volume"])}</td>
                                        }
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            {isIOS() && (
                                <div className={"ios-buttons"}>
                                    <FiChevronLeft size={32} color={"#111"} onClick={() => handleScroll('left')}
                                                   disabled={scrollLeft === 0}/>
                                    <FiChevronRight size={32} color={"#111"} onClick={() => handleScroll('right')}/>
                                </div>
                            )}
                        </div>
                }
            </div>
            <div className="wrapper grid-item5">
                <div className={"title-wrapper"}>
                    <h3 className={"title"}>{t('demandMap.mapTitle')}</h3>
                    <div className={"desktop"}>
                        <Tooltip title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('demandMap.mapInfo1')}</p>
                                    <p>
                                        {t('demandMap.mapTitle')}
                                    </p>
                                    <p className={"tip-title"}>{t('demandMap.mapInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                    <div className={"tablet-mobile"}>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={6000} title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('demandMap.mapInfo1')}</p>
                                    <p>
                                        {t('demandMap.mapTitle')}
                                    </p>
                                    <p className={"tip-title"}>{t('demandMap.mapInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                </div>
                {
                    mapImage ?
                        <div className="map-content">
                            <img src={mapImage} alt={t('demandMap.mapImgAlt')}/>
                            <div>
                                {
                                    !gmb ?
                                        null :
                                        <p>
                                            <span className={"map-competitor"}>1 </span>{gmb.title} <a href={gmb.url}
                                                                                                       target="_blank"
                                                                                                       rel="noreferrer"><FiExternalLink
                                            className={"external-link-icon"}/></a>
                                        </p>
                                }
                                {
                                    competitors && competitors.length ?
                                        competitors.error ?
                                            null :
                                            <>
                                                {
                                                    competitors.map((competitorGmbInfo, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {
                                                                    competitorGmbInfo && competitorGmbInfo.displayName ?
                                                                        <p><span
                                                                            className={"map-competitor"}>{index + 2} </span>{competitorGmbInfo.displayName.text}
                                                                            <a href={competitorGmbInfo.websiteUri}
                                                                               target="_blank"
                                                                               rel="noreferrer"><FiExternalLink
                                                                                className={"external-link-icon"}/></a>
                                                                        </p> :
                                                                        null
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }</> :
                                        competitorsGmbInfo && competitorsGmbInfo.length ?
                                            <>
                                                {
                                                    competitorsGmbInfo.map((competitor, index) => {
                                                        return (
                                                            <div key={index}>
                                                                {
                                                                    competitor.displayName ?
                                                                        <p><span
                                                                            className={"map-competitor"}>{index + 2} </span>{competitor.displayName.text}
                                                                            <a href={competitor.websiteUri}
                                                                               target="_blank"
                                                                               rel="noreferrer"><FiExternalLink
                                                                                className={"external-link-icon"}/></a>
                                                                        </p> :
                                                                        null
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }</> : !competitorsDfs && competitorsDfs.length === 0 ?
                                                <p>{t('demandMap.mapNoResult')}</p> :
                                                competitorsDfs.map((competitor, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {
                                                                competitor.displayName ?
                                                                    <p><span
                                                                        className={"map-competitor"}>{index + 2} </span>{competitor.displayName}
                                                                        <a href={competitor.websiteUri}
                                                                           target="_blank"
                                                                           rel="noreferrer"><FiExternalLink
                                                                            className={"external-link-icon"}/></a>
                                                                    </p> :
                                                                    null
                                                            }
                                                        </div>
                                                    )
                                                })
                                }
                            </div>
                        </div> :
                        <p>{t('demandMap.mapNoResult')}</p>
                }
            </div>
        </div>
    )

}

export default DemandMap;