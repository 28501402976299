import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from "./Home";
import FetchData from "./FetchData";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/:languageCode/:chosenRegion/:country/:name" element={<FetchData />} />
            </Routes>
        </Router>
    );
}
export default App;
