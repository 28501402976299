import '../App.css';
import React from "react";
import {FiExternalLink} from "react-icons/fi";
import {Tooltip} from "@mui/material";
import {IoMdInformationCircleOutline} from "react-icons/io";
import {useTranslation} from "react-i18next";


function SocialLinks({backlinks, socialMedia}) {
    const {t} = useTranslation("common");
    return (
        <div className="grid-container social-links">
            <div className="wrapper grid-item9">
                <div className={"title-wrapper"}>
                    <h3 className={"title"}>{t('socialLinks.socialTitle')}</h3>
                    <div className={"desktop"}>
                        <Tooltip title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('socialLinks.socialTitleInfo')}</p>
                                    <p>{t('socialLinks.socialInfo1')}</p>
                                    <ul>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle1')}</span>
                                            {t('socialLinks.socialLi1')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle2')}</span>
                                            {t('socialLinks.socialLi2')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle3')}</span>
                                            {t('socialLinks.socialLi3')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle4')}</span>
                                            {t('socialLinks.socialLi4')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle5')}</span>
                                            {t('socialLinks.socialLi5')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('socialLinks.socialInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                    <div className={"tablet-mobile"}>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={6000} title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('socialLinks.socialTitleInfo')}</p>
                                    <p>{t('socialLinks.socialInfo1')}</p>
                                    <ul>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle1')}</span>
                                            {t('socialLinks.socialLi1')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle2')}</span>
                                            {t('socialLinks.socialLi2')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle3')}</span>
                                            {t('socialLinks.socialLi3')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle4')}</span>
                                            {t('socialLinks.socialLi4')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.socialTipTitle5')}</span>
                                            {t('socialLinks.socialLi5')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('socialLinks.socialInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                </div>
                {
                    !socialMedia ?
                        <div>
                            <p>{t('socialLinks.socialNoResult')}</p>
                        </div> :
                        <div className={'results social'}>
                            {
                                !socialMedia.length || socialMedia.filter(item => item.link !== null).length === 0 ?
                                    <p>{t('socialLinks.socialInfo2')}</p> :
                                    socialMedia.filter(item => item.link !== null).map((item, index) => (
                                        <div key={index}>
                                            <h4>{item.social_media}</h4>
                                            {item.owned_by_business === true ? (
                                                <p>{t('socialLinks.socialVerified')}
                                                    <a href={item.link} target="_blank"
                                                       rel="noopener noreferrer"><FiExternalLink
                                                        className={"external-link-icon"}/></a>
                                                </p>
                                            ) : item.owned_by_business === null ? (
                                                <p>{t('socialLinks.socialMayBeVerified')}<a
                                                    href={item.link} target="_blank"
                                                    rel="noopener noreferrer"><FiExternalLink
                                                    className={"external-link-icon"}/></a></p>
                                            ) : (
                                                <p>{t('socialLinks.socialNotVerified')}<a
                                                    href={item.link} target="_blank"
                                                    rel="noopener noreferrer"><FiExternalLink
                                                    className={"external-link-icon"}/></a></p>
                                            )}
                                        </div>
                                    ))
                            }
                        </div>
                }
            </div>
            <div className="wrapper grid-item10">
                <div className={"title-wrapper"}>
                    <h3 className={"title"}>{t('socialLinks.linksTitle')}</h3>
                    <div className={"desktop"}>
                        <Tooltip title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('socialLinks.linksTitle')}</p>
                                    <p>{t('socialLinks.linksInfo1')}</p>
                                    <ul>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle1')}</span>
                                            {t('socialLinks.linksLi1')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle2')}</span>
                                            {t('socialLinks.linksLi2')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle3')}</span>
                                            {t('socialLinks.linksLi3')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle4')}</span>
                                            {t('socialLinks.linksLi4')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle5')}</span>
                                            {t('socialLinks.linksLi5')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('socialLinks.linksInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                    <div className={"tablet-mobile"}>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={6000} title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('socialLinks.linksTitle')}</p>
                                    <p>{t('socialLinks.linksInfo1')}</p>
                                    <ul>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle1')}</span>
                                            {t('socialLinks.linksLi1')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle2')}</span>
                                            {t('socialLinks.linksLi2')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle3')}</span>
                                            {t('socialLinks.linksLi3')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle4')}</span>
                                            {t('socialLinks.linksLi4')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('socialLinks.linksTipTitle5')}</span>
                                            {t('socialLinks.linksLi5')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('socialLinks.linksInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                </div>
                {
                    !Array.isArray(backlinks) || backlinks.length === 0 ?
                        <div>
                            <p>{t('socialLinks.linksNoResult')}</p>
                        </div> :
                        <div className={'results'}>
                            {
                                backlinks.map((backlink, index) => {
                                    return (
                                        <p key={index} className={"link"}>
                                            <span className={"local-link"}>{index + 1}</span> {backlink.domain}<a
                                            target="_blank"
                                            rel="noreferrer"
                                            href={backlink.link}><FiExternalLink
                                            className={"external-link-icon"}/></a>
                                        </p>
                                    )
                                })
                            }
                        </div>
                }
            </div>
        </div>
    )

}

export default SocialLinks;