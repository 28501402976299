import '../App.css';
import React, {useRef, useState} from "react";
import {Tooltip} from "@mui/material";
import {IoMdInformationCircleOutline} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {FiChevronLeft, FiChevronRight} from "react-icons/fi";
import {isIOS} from "../utils/utils";

function CrawlerPagespeed({
                              crawler,
                              structuredData,
                              pageSpeed,
                              homepageMetaDescription,
                              homepageTitleTag,
                              isSeoFriendly,
                              pageTitleDuplicate,
                              missingMetaDescription,
                              h1TitleDuplicate,
                              h2TitleDuplicate,
                              isRedirect,
                              clientErrors,
                              missingAltImagesDesc
                          }) {
    const {t} = useTranslation("common");

    const tableWrapperRef = useRef(null);
    const tableWrapperPageSpeedRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [scrollLeftPageSpeed, setScrollLeftPageSpeed] = useState(0);

    const handleScroll = (direction) => {
        const scrollAmount = 300; // Adjust this value to control the scroll distance
        const newScrollLeft = direction === 'left'
            ? Math.max(scrollLeft - scrollAmount, 0)
            : scrollLeft + scrollAmount;

        tableWrapperRef.current.scrollTo({
            left: newScrollLeft,
            behavior: 'smooth'
        });
        setScrollLeft(newScrollLeft);
    };

    const handleScrollPageSpeed = (direction) => {
        const scrollAmount = 300; // Adjust this value to control the scroll distance
        const newScrollLeft = direction === 'left'
            ? Math.max(scrollLeft - scrollAmount, 0)
            : scrollLeft + scrollAmount;

        tableWrapperPageSpeedRef.current.scrollTo({
            left: newScrollLeft,
            behavior: 'smooth'
        });
        setScrollLeftPageSpeed(newScrollLeft);
    };

    const getRating = (result) => {
        if (result >= 0 && result <= 0.49) {
            return t('crawlerPagespeed.crawlerSlow');
        } else if (result >= 0.50 && result <= 0.89) {
            return t('crawlerPagespeed.crawlerAverage');
        } else if (result >= 0.90 && result <= 1) {
            return t('crawlerPagespeed.crawlerFast');
        } else {
            return t('crawlerPagespeed.crawlerInvalid'); // in case the result is out of the expected range
        }
    };


    return (
        <div className="grid-container crawler-pagespeed">
            <div className="wrapper grid-item7 crawler">
                {!crawler && !homepageMetaDescription && !structuredData && !homepageTitleTag ?
                    <>
                        <div className={"title-wrapper"}>
                            <h3 className={"title"}>{t('crawlerPagespeed.crawlerTitle')}</h3>
                            <Tooltip enterTouchDelay={0} title={
                                <React.Fragment>
                                    <div className={"tip-wrapper"}>
                                        <p className={"tip-title"}>{t('crawlerPagespeed.crawlerTitle')}</p>
                                        <p>{t('crawlerPagespeed.crawlerInfo1')}</p>
                                        <p className={"tip-title"}>{t('crawlerPagespeed.crawlerInfo1')}</p>
                                    </div>
                                </React.Fragment>
                            } placement="top">
                                <div><IoMdInformationCircleOutline/></div>
                            </Tooltip>
                        </div>
                        <p>{t('crawlerPagespeed.crawlerNoResult')}</p>
                    </> :

                    <>
                        <div className={"title-wrapper"}>
                            <h3 className={"title"}>Crawler</h3>
                            <Tooltip enterTouchDelay={0} title={
                                <React.Fragment>
                                    <div className={"tip-wrapper"}>
                                        <p className={"tip-title"}>{t('crawlerPagespeed.crawlerTitle')}</p>
                                        <p>{t('crawlerPagespeed.crawlerInfo1')}</p>
                                        <p className={"tip-title"}>{t('crawlerPagespeed.crawlerInfo1')}</p>
                                    </div>
                                </React.Fragment>
                            } placement="top">
                                <div><IoMdInformationCircleOutline/></div>
                            </Tooltip>
                        </div>
                        <div className={"crawler-overview"}>
                            {
                                crawler && crawler.total_urls ?
                                    <div className={"total-crawler"}><h4>{t('crawlerPagespeed.crawlerTotalUrls')}</h4>
                                        <p>{crawler.total_urls}</p></div> :
                                    null
                            }
                            {
                                crawler && crawler.number_pages_crawled ?
                                    <div className={"total-crawler"}><h4>{t('crawlerPagespeed.crawlerTestedPages')}</h4>
                                        <p>{crawler.number_pages_crawled}</p></div> :
                                    null
                            }
                        </div>
                        <div className={"table-wrapper"} ref={tableWrapperRef}>
                            <table cellSpacing="0">
                                <thead>
                                <tr>
                                    <th>{t('crawlerPagespeed.crawlerTHead1')}</th>
                                    <th>{t('crawlerPagespeed.crawlerTHead2')}</th>
                                    <th>{t('crawlerPagespeed.crawlerTHead3')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{t('crawlerPagespeed.crawlerCat1')}</td>
                                    {(homepageMetaDescription && homepageTitleTag) && (homepageMetaDescription.status === "FAIL" || homepageTitleTag.status === "FAIL") ?
                                        <>
                                            <td className={"error"}>{t('crawlerPagespeed.crawlerFail')}</td>
                                            <td className={"error"}>{t('crawlerPagespeed.crawlerFailDesc')}</td>
                                        </> : (homepageMetaDescription && homepageTitleTag) && (homepageMetaDescription.status === "PASS" && homepageTitleTag.status === "PASS") ?
                                            <>
                                                <td>{t('crawlerPagespeed.crawlerPass')}</td>
                                                <td>{t('crawlerPagespeed.crawlerPassDesc')}</td>
                                            </> :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                <tr>
                                    <td>{t('crawlerPagespeed.crawlerCat2')}</td>
                                    {
                                        Object.keys(pageTitleDuplicate).length !== 0 ?
                                            pageTitleDuplicate.status === t('app.pass') ?
                                                <>
                                                    <td>{pageTitleDuplicate.status}</td>
                                                    <td>{pageTitleDuplicate.description}</td>
                                                </> :
                                                <>
                                                    <td className={"error"}>{pageTitleDuplicate.status}</td>
                                                    <td className={"error"}>{pageTitleDuplicate.description}</td>
                                                </>
                                            :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                <tr>
                                    <td>{t('crawlerPagespeed.crawlerCat3')}</td>
                                    {
                                        Object.keys(missingMetaDescription).length !== 0 ?
                                            missingMetaDescription.status === t('app.pass') ?
                                                <>
                                                    <td>{missingMetaDescription.status}</td>
                                                    <td>{missingMetaDescription.description}</td>
                                                </> :
                                                <>
                                                    <td className={"error"}>{missingMetaDescription.status}</td>
                                                    <td className={"error"}>{missingMetaDescription.description}</td>
                                                </> :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                <tr>
                                    <td>{t('crawlerPagespeed.crawlerCat4')}</td>
                                    {
                                        Object.keys(h1TitleDuplicate).length !== 0 ?
                                            h1TitleDuplicate.status === t('app.pass') ?
                                                <>
                                                    <td>{h1TitleDuplicate.status}</td>
                                                    <td>{h1TitleDuplicate.description}</td>
                                                </> :
                                                <>
                                                    <td className={"error"}>{h1TitleDuplicate.status}</td>
                                                    <td className={"error"}>{h1TitleDuplicate.description}</td>
                                                </> :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                <tr>
                                    <td>{t('crawlerPagespeed.crawlerCat5')}</td>
                                    {
                                        Object.keys(h2TitleDuplicate).length !== 0 ?
                                            h2TitleDuplicate.status === t('app.pass') ?
                                                <>
                                                    <td>{h2TitleDuplicate.status}</td>
                                                    <td>{h2TitleDuplicate.description}</td>
                                                </> :
                                                <>
                                                    <td className={"error"}>{h2TitleDuplicate.status}</td>
                                                    <td className={"error"}>{h2TitleDuplicate.description}</td>
                                                </> :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                <tr>
                                    <td>{t('crawlerPagespeed.crawlerCat6')}</td>
                                    {
                                        Object.keys(isRedirect).length !== 0 ?
                                            isRedirect.status === t('app.pass') ?
                                                <>
                                                    <td>{isRedirect.status}</td>
                                                    <td>{isRedirect.description}</td>
                                                </> :
                                                <>
                                                    <td className={"error"}>{isRedirect.status}</td>
                                                    <td className={"error"}>{isRedirect.description}</td>
                                                </> :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                <tr>
                                    <td>{t('crawlerPagespeed.crawlerCat7')}</td>
                                    {
                                        Object.keys(clientErrors).length !== 0 ?
                                            clientErrors.status === t('app.pass') ?
                                                <>
                                                    <td>{clientErrors.status}</td>
                                                    <td>{clientErrors.description}</td>
                                                </> :
                                                <>
                                                    <td className={"error"}>{clientErrors.status}</td>
                                                    <td className={"error"}>{clientErrors.description}</td>
                                                </> :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                <tr>
                                    <td>{t('crawlerPagespeed.crawlerCat8')}</td>
                                    {
                                        Object.keys(missingAltImagesDesc).length !== 0 ?
                                            missingAltImagesDesc.status === t('app.pass') ?
                                                <>
                                                    <td>{missingAltImagesDesc.status}</td>
                                                    <td>{missingAltImagesDesc.description}</td>
                                                </> :
                                                <>
                                                    <td className={"error"}>{missingAltImagesDesc.status}</td>
                                                    <td className={"error"}>{missingAltImagesDesc.description}</td>
                                                </> :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                <tr>
                                    <td>{t('crawlerPagespeed.crawlerCat9')}</td>
                                    {
                                        Object.keys(isSeoFriendly).length !== 0 ?
                                            isSeoFriendly.status === t('app.pass') ?
                                                <>
                                                    <td>{isSeoFriendly.status}</td>
                                                    <td>{isSeoFriendly.description}</td>
                                                </> :
                                                <>
                                                    <td className={"error"}>{isSeoFriendly.status}</td>
                                                    <td className={"error"}>{isSeoFriendly.description}</td>
                                                </> :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                <tr className={"last-child"}>
                                    <td>{t('crawlerPagespeed.crawlerCat10')}</td>
                                    {structuredData && structuredData.status && structuredData.description ?
                                        structuredData.status === "PASS" ?
                                            <>
                                                <td>{t('app.pass')}</td>
                                                <td>{t('crawlerPagespeed.crawlerPassDesc')}</td>
                                            </> :
                                            <>
                                                <td className={"error"}>{t('app.fail')}</td>
                                                <td className={"error"}>{t('crawlerPagespeed.crawlerFailDesc')}</td>
                                            </> :
                                        structuredData ?
                                            <>
                                                <td className={"error"}>{t('crawlerPagespeed.crawlerFail')}</td>
                                                <td className={"error"}>{t('crawlerPagespeed.crawlerFailDesc')}</td>
                                            </> :
                                            <>
                                                <td>{t('crawlerPagespeed.noData')}</td>
                                                <td>{t('crawlerPagespeed.crawlerNoDataDesc')}</td>
                                            </>
                                    }
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        {isIOS() && (
                            <div className={"ios-buttons"}>
                                <FiChevronLeft size={32} color={"#111"} onClick={() => handleScroll('left')}
                                               disabled={scrollLeft === 0}/>
                                <FiChevronRight size={32} color={"#111"} onClick={() => handleScroll('right')}/>
                            </div>
                        )}
                    </>}
            </div>
            <div className="wrapper grid-item8 pagespeed">
                {
                    !pageSpeed ?
                        <div>
                            <div className={"title-wrapper"}>
                                <h3 className={"title"}>{t('crawlerPagespeed.pagespeedTitle')}</h3>
                                <Tooltip enterTouchDelay={0} title={
                                    <React.Fragment>
                                        <div className={"tip-wrapper"}>
                                            <p className={"tip-title"}>{t('crawlerPagespeed.pagespeedTitle')}</p>
                                            <p>{t('crawlerPagespeed.pagespeedInfo1')}</p>
                                            <p>{t('crawlerPagespeed.pagespeedInfo2')}</p>
                                            <ul>
                                                <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle1')}</span>
                                                    {t('crawlerPagespeed.li1')}
                                                </li>
                                                <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle2')}</span>
                                                    {t('crawlerPagespeed.li2')}
                                                </li>
                                                <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle3')}</span>
                                                    {t('crawlerPagespeed.li3')}
                                                </li>
                                                <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle4')}</span>
                                                    {t('crawlerPagespeed.li4')}
                                                </li>
                                            </ul>
                                            <p className={"tip-title"}>{t('crawlerPagespeed.pagespeedInfo3')}</p>
                                        </div>
                                    </React.Fragment>
                                } placement="top">
                                    <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                                </Tooltip>
                            </div>
                            <p>{t('crawlerPagespeed.pagespeedNoResult')}</p>
                        </div> :
                        pageSpeed && pageSpeed.error ?
                            <div>
                                <div className={"title-wrapper"}>
                                    <h3 className={"title"}>{t('crawlerPagespeed.pagespeedTitle')}</h3>
                                    <Tooltip enterTouchDelay={0} title={
                                        <React.Fragment>
                                            <div className={"tip-wrapper"}>
                                                <p className={"tip-title"}>{t('crawlerPagespeed.pagespeedTitle')}</p>
                                                <p>{t('crawlerPagespeed.pagespeedInfo1')}</p>
                                                <p>{t('crawlerPagespeed.pagespeedInfo2')}</p>
                                                <ul>
                                                    <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle1')}</span>
                                                        {t('crawlerPagespeed.li1')}
                                                    </li>
                                                    <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle2')}</span>
                                                        {t('crawlerPagespeed.li2')}
                                                    </li>
                                                    <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle3')}</span>
                                                        {t('crawlerPagespeed.li3')}
                                                    </li>
                                                    <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle4')}</span>
                                                        {t('crawlerPagespeed.li4')}
                                                    </li>
                                                </ul>
                                                <p className={"tip-title"}>{t('crawlerPagespeed.pagespeedInfo3')}</p>
                                            </div>
                                        </React.Fragment>
                                    } placement="top">
                                        <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                                    </Tooltip>
                                </div>
                                <p>{t('crawlerPagespeed.pagespeedNoResult')}</p>
                            </div> :
                            <div className={'results'}>
                                <div className={"title-wrapper"}>
                                    <h3 className={"title"}>{t('crawlerPagespeed.pagespeedTitle')}</h3>
                                    <Tooltip enterTouchDelay={0} title={
                                        <React.Fragment>
                                            <div className={"tip-wrapper"}>
                                                <p className={"tip-title"}>{t('crawlerPagespeed.pagespeedTitle')}</p>
                                                <p>{t('crawlerPagespeed.pagespeedInfo1')}</p>
                                                <p>{t('crawlerPagespeed.pagespeedInfo2')}</p>
                                                <ul>
                                                    <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle1')}</span>
                                                        {t('crawlerPagespeed.li1')}
                                                    </li>
                                                    <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle2')}</span>
                                                        {t('crawlerPagespeed.li2')}
                                                    </li>
                                                    <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle3')}</span>
                                                        {t('crawlerPagespeed.li3')}
                                                    </li>
                                                    <li>
                                                    <span
                                                        className={"tip-title"}>{t('crawlerPagespeed.tipTitle4')}</span>
                                                        {t('crawlerPagespeed.li4')}
                                                    </li>
                                                </ul>
                                                <p className={"tip-title"}>{t('crawlerPagespeed.pagespeedInfo3')}</p>
                                            </div>
                                        </React.Fragment>
                                    } placement="top">
                                        <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                                    </Tooltip>
                                </div>
                                {
                                    Math.round(pageSpeed.lighthouseResult.categories.performance.score * 100) ?
                                        getRating(pageSpeed.lighthouseResult.categories.performance.score) === "Poor" ?
                                            <div><h4>{t('crawlerPagespeed.pagespeedPerformanceScore')}</h4> <p
                                                className={"error"}>{Math.round(pageSpeed.lighthouseResult.categories.performance.score * 100)}%
                                                - {getRating(pageSpeed.lighthouseResult.categories.performance.score)}</p>
                                            </div> :
                                            <div><h4>{t('crawlerPagespeed.pagespeedPerformanceScore')}</h4>
                                                <p>{Math.round(pageSpeed.lighthouseResult.categories.performance.score * 100)}%
                                                    - {getRating(pageSpeed.lighthouseResult.categories.performance.score)}</p>
                                            </div> :
                                        <div><h4>{t('crawlerPagespeed.pagespeedPerformanceScore')}</h4> <p>0%
                                            - {getRating(pageSpeed.lighthouseResult.categories.performance.score)}</p>
                                        </div>
                                }
                                <div className={"table-wrapper"} ref={tableWrapperPageSpeedRef}>
                                    <table cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th>{t('crawlerPagespeed.pagespeedTHead1')}</th>
                                            <th>{t('crawlerPagespeed.pagespeedTHead2')}</th>
                                            <th>{t('crawlerPagespeed.pagespeedTHead3')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            pageSpeed.loadingExperience.metrics ?
                                                <>
                                                    <tr>
                                                        <td>{t('crawlerPagespeed.pagespeedCat1')}</td>
                                                        {
                                                            pageSpeed.loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE ?
                                                                pageSpeed.loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.category === "SLOW" ?
                                                                    <>
                                                                        <td className={"error"}>{pageSpeed.loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile}</td>
                                                                        <td className={"error"}>{t('crawlerPagespeed.crawlerSlow')}</td>
                                                                    </> : pageSpeed.loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.category === "AVERAGE" ?
                                                                        <>
                                                                            <td>{pageSpeed.loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile}</td>
                                                                            <td>{t('crawlerPagespeed.crawlerAverage')}</td>
                                                                        </> : pageSpeed.loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.category === "FAST" ?
                                                                            <>
                                                                                <td>{pageSpeed.loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile}</td>
                                                                                <td>{t('crawlerPagespeed.crawlerFast')}</td>
                                                                            </> :
                                                                            <>
                                                                                <td>{pageSpeed.loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.percentile}</td>
                                                                                <td>{pageSpeed.loadingExperience.metrics.CUMULATIVE_LAYOUT_SHIFT_SCORE.category}</td>
                                                                            </> :
                                                                <>
                                                                    <td>0</td>
                                                                    <td>{t('crawlerPagespeed.noData')}</td>
                                                                </>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>{t('crawlerPagespeed.pagespeedCat2')}</td>
                                                        {
                                                            pageSpeed.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS ?
                                                                pageSpeed.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.category === "SLOW" ?
                                                                    <>
                                                                        <td className={"error"}>{pageSpeed.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.percentile}</td>
                                                                        <td className={"error"}>{t('crawlerPagespeed.crawlerSlow')}</td>
                                                                    </> : pageSpeed.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.category === "AVERAGE" ?
                                                                        <>
                                                                            <td>{pageSpeed.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.percentile}</td>
                                                                            <td>{t('crawlerPagespeed.crawlerAverage')}</td>
                                                                        </> : pageSpeed.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.category === "FAST" ?
                                                                            <>
                                                                                <td>{pageSpeed.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.percentile}</td>
                                                                                <td>{t('crawlerPagespeed.crawlerFast')}</td>
                                                                            </> :
                                                                            <>
                                                                                <td>{pageSpeed.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.percentile}</td>
                                                                                <td>{pageSpeed.loadingExperience.metrics.FIRST_CONTENTFUL_PAINT_MS.category}</td>
                                                                            </> :
                                                                <>
                                                                    <td>0</td>
                                                                    <td>{t('crawlerPagespeed.noData')}</td>
                                                                </>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>{t('crawlerPagespeed.pagespeedCat3')}</td>
                                                        {
                                                            pageSpeed.loadingExperience.metrics.FIRST_INPUT_DELAY_MS ?
                                                                pageSpeed.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.category === "SLOW" ?
                                                                    <>
                                                                        <td className={"error"}>{pageSpeed.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.percentile}</td>
                                                                        <td className={"error"}>{t('crawlerPagespeed.crawlerSlow')}</td>
                                                                    </> : pageSpeed.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.category === "AVERAGE" ?
                                                                        <>
                                                                            <td>{pageSpeed.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.percentile}</td>
                                                                            <td>{t('crawlerPagespeed.crawlerAverage')}</td>
                                                                        </> : pageSpeed.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.category === "FAST" ?
                                                                            <>
                                                                                <td>{pageSpeed.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.percentile}</td>
                                                                                <td>{t('crawlerPagespeed.crawlerFast')}</td>
                                                                            </> :
                                                                            <>
                                                                                <td>{pageSpeed.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.percentile}</td>
                                                                                <td>{pageSpeed.loadingExperience.metrics.FIRST_INPUT_DELAY_MS.category}</td>
                                                                            </> :
                                                                <>
                                                                    <td>0</td>
                                                                    <td>{t('crawlerPagespeed.noData')}</td>
                                                                </>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td>{t('crawlerPagespeed.pagespeedCat4')}</td>
                                                        {
                                                            pageSpeed.loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS ?
                                                                pageSpeed.loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.category === "SLOW" ?
                                                                    <>
                                                                        <td className={"error"}>{pageSpeed.loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.percentile}</td>
                                                                        <td className={"error"}>{t('crawlerPagespeed.crawlerSlow')}</td>
                                                                    </> : pageSpeed.loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.category === "AVERAGE" ?
                                                                        <>
                                                                            <td>{pageSpeed.loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.percentile}</td>
                                                                            <td>{t('crawlerPagespeed.crawlerAverage')}</td>
                                                                        </> : pageSpeed.loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.category === "FAST" ?
                                                                            <>
                                                                                <td>{pageSpeed.loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.percentile}</td>
                                                                                <td>{t('crawlerPagespeed.crawlerFast')}</td>
                                                                            </> :
                                                                            <>
                                                                                <td>{pageSpeed.loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.percentile}</td>
                                                                                <td>{pageSpeed.loadingExperience.metrics.LARGEST_CONTENTFUL_PAINT_MS.category}</td>
                                                                            </> :
                                                                <>
                                                                    <td>0</td>
                                                                    <td>{t('crawlerPagespeed.noData')}</td>
                                                                </>
                                                        }
                                                    </tr>
                                                </> :
                                                <>
                                                    <tr>
                                                        <td>{t('crawlerPagespeed.pagespeedCat1')}</td>
                                                        <td>{t('crawlerPagespeed.pagespeedNoDataDesc')}</td>
                                                        <td>{t('crawlerPagespeed.noData')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('crawlerPagespeed.pagespeedCat2')}</td>
                                                        <td>{t('crawlerPagespeed.pagespeedNoDataDesc')}</td>
                                                        <td>{t('crawlerPagespeed.noData')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('crawlerPagespeed.pagespeedCat3')}</td>
                                                        <td>{t('crawlerPagespeed.pagespeedNoDataDesc')}</td>
                                                        <td>{t('crawlerPagespeed.noData')}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{t('crawlerPagespeed.pagespeedCat4')}</td>
                                                        <td>{t('crawlerPagespeed.pagespeedNoDataDesc')}</td>
                                                        <td>{t('crawlerPagespeed.noData')}</td>
                                                    </tr>
                                                </>
                                        }
                                        <tr>
                                            <td>{t('crawlerPagespeed.pagespeedCat5')}</td>
                                            {
                                                getRating(pageSpeed.lighthouseResult.audits["cumulative-layout-shift"].score) === t('crawlerPagespeed.crawlerSlow') ?
                                                    <>
                                                        <td className={"error"}>{pageSpeed.lighthouseResult.audits["cumulative-layout-shift"].displayValue}</td>
                                                        <td className={"error"}>{getRating(pageSpeed.lighthouseResult.audits["cumulative-layout-shift"].score)}</td>
                                                    </> :
                                                    <>
                                                        <td>{pageSpeed.lighthouseResult.audits["cumulative-layout-shift"].displayValue}</td>
                                                        <td>{getRating(pageSpeed.lighthouseResult.audits["cumulative-layout-shift"].score)}</td>
                                                    </>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{t('crawlerPagespeed.pagespeedCat6')}</td>
                                            {
                                                getRating(pageSpeed.lighthouseResult.audits["first-contentful-paint"].score) === t('crawlerPagespeed.crawlerSlow') ?
                                                    <>
                                                        <td className={"error"}>{pageSpeed.lighthouseResult.audits["first-contentful-paint"].displayValue}</td>
                                                        <td className={"error"}>{getRating(pageSpeed.lighthouseResult.audits["first-contentful-paint"].score)}</td>
                                                    </> :
                                                    <>
                                                        <td>{pageSpeed.lighthouseResult.audits["first-contentful-paint"].displayValue}</td>
                                                        <td>{getRating(pageSpeed.lighthouseResult.audits["first-contentful-paint"].score)}</td>
                                                    </>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{t('crawlerPagespeed.pagespeedCat7')}</td>
                                            {
                                                getRating(pageSpeed.lighthouseResult.audits["speed-index"].score) === t('crawlerPagespeed.crawlerSlow') ?
                                                    <>
                                                        <td className={"error"}>{pageSpeed.lighthouseResult.audits["speed-index"].displayValue}</td>
                                                        <td className={"error"}>{getRating(pageSpeed.lighthouseResult.audits["speed-index"].score)}</td>
                                                    </> :
                                                    <>
                                                        <td>{pageSpeed.lighthouseResult.audits["speed-index"].displayValue}</td>
                                                        <td>{getRating(pageSpeed.lighthouseResult.audits["speed-index"].score)}</td>
                                                    </>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{t('crawlerPagespeed.pagespeedCat8')}</td>
                                            {
                                                getRating(pageSpeed.lighthouseResult.audits["largest-contentful-paint"].score) === t('crawlerPagespeed.crawlerSlow') ?
                                                    <>
                                                        <td className={"error"}>{pageSpeed.lighthouseResult.audits["largest-contentful-paint"].displayValue}</td>
                                                        <td className={"error"}>{getRating(pageSpeed.lighthouseResult.audits["largest-contentful-paint"].score)}</td>
                                                    </> :
                                                    <>
                                                        <td>{pageSpeed.lighthouseResult.audits["largest-contentful-paint"].displayValue}</td>
                                                        <td>{getRating(pageSpeed.lighthouseResult.audits["largest-contentful-paint"].score)}</td>
                                                    </>
                                            }
                                        </tr>
                                        <tr>
                                            <td>{t('crawlerPagespeed.pagespeedCat9')}</td>
                                            {
                                                getRating(pageSpeed.lighthouseResult.audits["total-blocking-time"].score) === t('crawlerPagespeed.crawlerSlow') ?
                                                    <>
                                                        <td className={"error"}>{pageSpeed.lighthouseResult.audits["total-blocking-time"].displayValue}</td>
                                                        <td className={"error"}>{getRating(pageSpeed.lighthouseResult.audits["total-blocking-time"].score)}</td>
                                                    </> :
                                                    <>
                                                        <td>{pageSpeed.lighthouseResult.audits["total-blocking-time"].displayValue}</td>
                                                        <td>{getRating(pageSpeed.lighthouseResult.audits["total-blocking-time"].score)}</td>
                                                    </>
                                            }
                                        </tr>
                                        <tr className={"last-child"}>
                                            <td>{t('crawlerPagespeed.pagespeedCat10')}</td>
                                            {
                                                getRating(pageSpeed.lighthouseResult.audits.interactive.score) === t('crawlerPagespeed.crawlerSlow') ?
                                                    <>
                                                        <td className={"error"}>{pageSpeed.lighthouseResult.audits.interactive.displayValue}</td>
                                                        <td className={"error"}>{getRating(pageSpeed.lighthouseResult.audits.interactive.score)}</td>
                                                    </> :
                                                    <>
                                                        <td>{pageSpeed.lighthouseResult.audits.interactive.displayValue}</td>
                                                        <td>{getRating(pageSpeed.lighthouseResult.audits.interactive.score)}</td>
                                                    </>
                                            }
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {isIOS() && (
                                    <div className={"ios-buttons"}>
                                        <FiChevronLeft size={32} color={"#111"} disabled={scrollLeftPageSpeed === 0}
                                                       onClick={() => handleScrollPageSpeed('left')}/>
                                        <FiChevronRight size={32} color={"#111"}
                                                        onClick={() => handleScrollPageSpeed('right')}/>
                                    </div>
                                )}
                            </div>
                }
            </div>
        </div>
    )

}

export default CrawlerPagespeed;