import '../App.css';
import React, {useRef, useState} from "react";
import {FiChevronLeft, FiChevronRight, FiExternalLink} from "react-icons/fi";
import {Tooltip} from "@mui/material";
import {IoMdInformationCircleOutline} from "react-icons/io";
import {useTranslation} from "react-i18next";
import {isIOS} from '../utils/utils';


function Competitors({
                         competitors,
                         competitorsOrganicSearch,
                         gmb,
                         businessName,
                         domainOverview,
                         backlinkOverview,
                         cat1,
                         cat2,
                         structuredData,
                         pageSpeed,
                         blogPosts,
                         competitorsDomainOverview,
                         competitorsBacklinksOverview,
                         competitorsStructuredData,
                         competitorsPagespeed,
                         competitorsBlogPosts,
                         competitorsGmbInfo,
                         competitorsDfs
                     }) {

    const {t} = useTranslation("common");
    const tableWrapperRef = useRef(null);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleScroll = (direction) => {
        const scrollAmount = 300; // Adjust this value to control the scroll distance
        const newScrollLeft = direction === 'left'
            ? Math.max(scrollLeft - scrollAmount, 0)
            : scrollLeft + scrollAmount;

        tableWrapperRef.current.scrollTo({
            left: newScrollLeft,
            behavior: 'smooth'
        });
        setScrollLeft(newScrollLeft);
    };

    const getStructuredData = (result) => {
        if (result === "FAIL") {
            return t('competition.fail');
        } else if (result === "PASS") {
            return t('competition.pass');
        } else {
            return t('competition.invalid'); // in case the result is out of the expected range
        }
    };

    return (
        <div className="grid-container competitors">
            <div className="wrapper grid-item6">
                <div className={"title-wrapper"}>
                    <h3 className={"title"}>{t('competition.title')}</h3>
                    <div className={"desktop"}>
                        <Tooltip title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('competition.title')}</p>
                                    <p>{t('competition.info1')}</p>
                                    <ul>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle1')}</span>
                                            {t('competition.li1')}
                                        </li>
                                        <li>
                                            <span className={"tip-title"}>{t('competition.tipTitle2')}</span>
                                            {t('competition.li2')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle3')}</span>
                                            {t('competition.li3')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle4')}</span>
                                            {t('competition.li4')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle5')}</span>
                                            {t('competition.li5')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle6')}</span>
                                            {t('competition.li6')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle7')}</span>
                                            {t('competition.li7')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle8')}</span>
                                            {t('competition.li8')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle9')}</span>
                                            {t('competition.li9')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle10')}</span>
                                            {t('competition.li10')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle11')}</span>
                                            {t('competition.li11')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle12')}</span>
                                            {t('competition.li12')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle13')}</span>
                                            {t('competition.li13')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('competition.info2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                    <div className={"tablet-mobile"}>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={6000} title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('competition.title')}</p>
                                    <p>{t('competition.info1')}</p>
                                    <ul>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle1')}</span>
                                            {t('competition.li1')}
                                        </li>
                                        <li>
                                            <span className={"tip-title"}>{t('competition.tipTitle2')}</span>
                                            {t('competition.li2')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle3')}</span>
                                            {t('competition.li3')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle4')}</span>
                                            {t('competition.li4')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle5')}</span>
                                            {t('competition.li5')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle6')}</span>
                                            {t('competition.li6')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle7')}</span>
                                            {t('competition.li7')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle8')}</span>
                                            {t('competition.li8')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle9')}</span>
                                            {t('competition.li9')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle10')}</span>
                                            {t('competition.li10')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle11')}</span>
                                            {t('competition.li11')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle12')}</span>
                                            {t('competition.li12')}
                                        </li>
                                        <li><span className={"tip-title"}>{t('competition.tipTitle13')}</span>
                                            {t('competition.li13')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('competition.info2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                </div>
                {
                    gmb && competitors && competitors.length ?
                        competitors.error ?
                            <p>{t('competition.noResult')}</p> :
                            <div className={'results'}>
                                <div className={"table-wrapper"} ref={tableWrapperRef}>
                                    <table cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('competition.tHead1')}</th>
                                            <th>{t('competition.tHead2')}</th>
                                            <th>{t('competition.tHead3')}</th>
                                            <th>{t('competition.tHead4')}</th>
                                            <th>{t('competition.tHead5')}</th>
                                            <th>{t('competition.tHead6')}</th>
                                            <th>{t('competition.tHead7')}</th>
                                            <th>{t('competition.tHead8')}</th>
                                            <th>{t('competition.tHead9')}</th>
                                            <th>{t('competition.tHead10')}</th>
                                            <th>{t('competition.tHead11')}</th>
                                            <th>{t('competition.tHead12')}</th>
                                            <th>{t('competition.tHead13')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>

                                            <td className={"competitor-number"}>1</td>
                                            {
                                                gmb.url && gmb.title ?
                                                    <td className={"competitor-link"}>{gmb.title} <a href={gmb.url}
                                                                                                     target="_blank"
                                                                                                     rel="noreferrer"><FiExternalLink
                                                        className={"external-link-icon"}/></a></td> :
                                                    gmb.title ?
                                                        <td>{gmb.title}</td> :
                                                        <td>{businessName}</td>
                                            }
                                            <td>0m</td>
                                            {!domainOverview || domainOverview.error ?
                                                <td>0</td> :
                                                <td>{domainOverview[0]['Organic Traffic']}</td>
                                            }
                                            {
                                                backlinkOverview && backlinkOverview.ascore ?
                                                    <td>{backlinkOverview.ascore}</td> :
                                                    <td>0</td>
                                            }
                                            {
                                                backlinkOverview && backlinkOverview.total ?
                                                    <td>{backlinkOverview.total}</td> :
                                                    <td>0</td>
                                            }
                                            <td>{cat1}</td>
                                            <td>{cat2}</td>
                                            {
                                                gmb && gmb.rating ?
                                                    <td>{gmb.rating.votes_count}</td> :
                                                    <td>0</td>
                                            }
                                            {
                                                gmb && gmb.rating ?
                                                    <td>{gmb.rating.value}/5</td> :
                                                    <td>0/5</td>
                                            }
                                            {
                                                !pageSpeed ?
                                                    <td>0</td> :
                                                    pageSpeed && pageSpeed.error ?
                                                        <td>0</td> :
                                                        pageSpeed && Math.round(pageSpeed.lighthouseResult.categories.performance.score * 100) ?
                                                            <td>{Math.round(pageSpeed.lighthouseResult.categories.performance.score * 100)}</td> :
                                                            <td>0</td>
                                            }
                                            {
                                                blogPosts ?
                                                    <td>{blogPosts}</td> :
                                                    <td>0</td>
                                            }
                                            {
                                                gmb.total_photos ?
                                                    <td>{gmb.total_photos}</td> :
                                                    <td>0</td>
                                            }
                                            {structuredData && structuredData.status ?
                                                <td>{getStructuredData(structuredData.status)}</td> :
                                                <td>{t('competition.structuredDataStatus')}</td>
                                            }
                                        </tr>
                                        {
                                            competitors.map((competitorGmbInfo, key) => {
                                                let competitorCat1 = t('competition.catNone');
                                                let competitorCat2 = t('competition.catNone');

                                                if (competitorGmbInfo && competitorGmbInfo.primaryTypeDisplayName && competitorGmbInfo.primaryTypeDisplayName.text) {
                                                    competitorCat1 = competitorGmbInfo.primaryTypeDisplayName.text;
                                                    if (competitorGmbInfo.types && competitorGmbInfo.types.length > 0) {
                                                        const formattedTypes = competitorGmbInfo.types.map(type => {
                                                            return type.charAt(0).toUpperCase() + type.replaceAll('_', ' ').slice(1);
                                                        });
                                                        const filteredTypes = formattedTypes.filter(type => type !== "Point of interest" && type !== "Establishment" && type !== "Food");
                                                        if (filteredTypes.length > 1) {
                                                            let catType1 = filteredTypes[0];
                                                            // Find the next available category that is different from competitorCat1
                                                            competitorCat2 = filteredTypes.find(type => type !== catType1) || t('competition.catNone');
                                                        }
                                                    }
                                                } else if (competitorGmbInfo && competitorGmbInfo.types && competitorGmbInfo.types.length > 0) {
                                                    const formattedTypes = competitorGmbInfo.types.map(type => {
                                                        return type.charAt(0).toUpperCase() + type.replaceAll('_', ' ').slice(1);
                                                    });
                                                    // Filter out unwanted categories
                                                    const filteredTypes = formattedTypes.filter(type => type !== "Point of interest" && type !== "Establishment");
                                                    // Assign filtered types to categories
                                                    if (filteredTypes.length > 0) {
                                                        competitorCat1 = filteredTypes[0];
                                                        // Find the next available category that is different from competitorCat1
                                                        competitorCat2 = filteredTypes.find(type => type !== competitorCat1) || t('competition.catNone');
                                                    }
                                                }

                                                return (
                                                    <tr key={key}>
                                                        <td className={"competitor-number"}>{key + 2}</td>
                                                        {
                                                            competitorGmbInfo && competitorGmbInfo.websiteUri && competitorGmbInfo.displayName ?
                                                                <td className={"competitor-link"}>{competitorGmbInfo.displayName.text}
                                                                    <a
                                                                        href={competitorGmbInfo.websiteUri}
                                                                        target="_blank"
                                                                        rel="noreferrer"><FiExternalLink
                                                                        className={"external-link-icon"}/></a>
                                                                </td> :
                                                                competitorGmbInfo && competitorGmbInfo.displayName ?
                                                                    <td>{competitorGmbInfo.displayName.text}</td> :
                                                                    <td>{t('competition.noName')}</td>

                                                        }
                                                        {
                                                            competitorGmbInfo && competitorGmbInfo.distance ?
                                                                <td>{competitorGmbInfo.distance}m</td> :
                                                                <td>0m</td>
                                                        }
                                                        {
                                                            competitorsDomainOverview[key].error ?
                                                                <td>0</td> :
                                                                <td>{competitorsDomainOverview[key][0]['Organic Traffic']}</td>
                                                        }
                                                        {
                                                            competitorsBacklinksOverview && competitorsBacklinksOverview[key].error ?
                                                                <>
                                                                    <td>0</td>
                                                                    <td>0</td>
                                                                </> :
                                                                competitorsBacklinksOverview && competitorsBacklinksOverview[key][0].ascore && competitorsBacklinksOverview[key][0].total ?
                                                                    <>
                                                                        <td>{competitorsBacklinksOverview[key][0].ascore}</td>
                                                                        <td>{competitorsBacklinksOverview[key][0].total}</td>
                                                                    </> :
                                                                    competitorsBacklinksOverview && competitorsBacklinksOverview[key][0].ascore ?
                                                                        <>
                                                                            <td>{competitorsBacklinksOverview[key][0].ascore}</td>
                                                                            <td>0</td>
                                                                        </> :
                                                                        <>
                                                                            <td>0</td>
                                                                            <td>{competitorsBacklinksOverview[key][0].total}</td>
                                                                        </>

                                                        }
                                                        <td>{competitorCat1}</td>
                                                        <td>{competitorCat2}</td>
                                                        {
                                                            competitorGmbInfo && competitorGmbInfo.rating && competitorGmbInfo.userRatingCount ?
                                                                <>
                                                                    <td>{competitorGmbInfo.userRatingCount}</td>
                                                                    <td>{competitorGmbInfo.rating}/5</td>
                                                                </> :
                                                                <>
                                                                    <td>0</td>
                                                                    <td>0/5</td>
                                                                </>
                                                        }
                                                        {
                                                            competitorsPagespeed && competitorsPagespeed.length && competitorsPagespeed[key] ?
                                                                <td>{competitorsPagespeed[key]}</td> :
                                                                <td>0</td>
                                                        }
                                                        {
                                                            competitorsBlogPosts && competitorsBlogPosts.length ?
                                                                <td>{competitorsBlogPosts[key]}</td> :
                                                                <td>0</td>
                                                        }
                                                        {
                                                            competitorGmbInfo.photos && competitorGmbInfo.photos.length ?
                                                                competitorGmbInfo.photos.length === 10 ?
                                                                    <td>10+</td> :
                                                                    <td>{competitorGmbInfo.photos.length}</td> :
                                                                <td>0</td>
                                                        }
                                                        {competitorsStructuredData && competitorsStructuredData.length ?
                                                            <td>{getStructuredData(competitorsStructuredData[key].status)}</td> :
                                                            <td>{t('competition.structuredDataStatus')}</td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                {isIOS() && (
                                    <div className={"ios-buttons"}>
                                        <FiChevronLeft size={32} color={"#111"} disabled={scrollLeft === 0}
                                                       onClick={() => handleScroll('left')}/>
                                        <FiChevronRight size={32} color={"#111"}
                                                        onClick={() => handleScroll('right')}/>
                                    </div>
                                )}
                            </div> :
                        !gmb || competitorsOrganicSearch.error ?
                            <p>{t('competition.noResult')}</p> :
                            <div className={'results'}>
                                <div className={"table-wrapper"} ref={tableWrapperRef}>
                                    <table cellSpacing="0">
                                        <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('competition.tHead1')}</th>
                                            <th>{t('competition.tHead2')}</th>
                                            <th>{t('competition.tHead3')}</th>
                                            <th>{t('competition.tHead4')}</th>
                                            <th>{t('competition.tHead5')}</th>
                                            <th>{t('competition.tHead6')}</th>
                                            <th>{t('competition.tHead7')}</th>
                                            <th>{t('competition.tHead8')}</th>
                                            <th>{t('competition.tHead9')}</th>
                                            <th>{t('competition.tHead10')}</th>
                                            <th>{t('competition.tHead11')}</th>
                                            <th>{t('competition.tHead12')}</th>
                                            <th>{t('competition.tHead13')}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>

                                            <td className={"competitor-number"}>1</td>
                                            {
                                                gmb.url && gmb.title ?
                                                    <td className={"competitor-link"}>
                                                        {gmb.title} <a href={gmb.url} target="_blank"
                                                                       rel="noreferrer"><FiExternalLink
                                                        className={"external-link-icon"}/></a>
                                                    </td> : gmb.title ?
                                                        <td>{gmb.title}</td> :
                                                        <td>{businessName}</td>
                                            }
                                            <td>0m</td>
                                            {!domainOverview || domainOverview.error ?
                                                <td>0</td> :
                                                <td>{domainOverview[0]['Organic Traffic']}</td>
                                            }
                                            {
                                                backlinkOverview && backlinkOverview.ascore ?
                                                    <td>{backlinkOverview.ascore}</td> :
                                                    <td>0</td>
                                            }
                                            {
                                                backlinkOverview && backlinkOverview.total ?
                                                    <td>{backlinkOverview.total}</td> :
                                                    <td>0</td>
                                            }
                                            <td>{cat1}</td>
                                            <td>{cat2}</td>
                                            {
                                                gmb && gmb.rating ?
                                                    <td>{gmb.rating.votes_count}</td> :
                                                    <td>0</td>
                                            }
                                            {
                                                gmb && gmb.rating ?
                                                    <td>{gmb.rating.value}/5</td> :
                                                    <td>0/5</td>
                                            }
                                            {
                                                !pageSpeed ?
                                                    <td>0</td> :
                                                    pageSpeed && pageSpeed.error ?
                                                        <td>0</td> :
                                                        pageSpeed && Math.round(pageSpeed.lighthouseResult.categories.performance.score * 100) ?
                                                            <td>{Math.round(pageSpeed.lighthouseResult.categories.performance.score * 100)}</td> :
                                                            <td>0</td>
                                            }
                                            {
                                                blogPosts ?
                                                    <td>{blogPosts}</td> :
                                                    <td>0</td>
                                            }
                                            {
                                                gmb.total_photos ?
                                                    <td>{gmb.total_photos}</td> :
                                                    <td>0</td>
                                            }
                                            {structuredData && structuredData.status ?
                                                <td>{getStructuredData(structuredData.status)}</td> :
                                                <td>{t('competition.structuredDataStatus')}</td>
                                            }
                                        </tr>
                                        {
                                            competitorsGmbInfo && competitorsGmbInfo.length ?
                                                competitorsGmbInfo.map((competitorGmbInfo, key) => {

                                                    let competitorCat1 = t('competition.catNone');
                                                    let competitorCat2 = t('competition.catNone');

                                                    if (competitorGmbInfo && competitorGmbInfo.primaryTypeDisplayName && competitorGmbInfo.primaryTypeDisplayName.text) {
                                                        competitorCat2 = competitorGmbInfo.primaryTypeDisplayName.text;
                                                        if (competitorGmbInfo.types && competitorGmbInfo.types.length > 0) {
                                                            const formattedTypes = competitorGmbInfo.types.map(type => {
                                                                return type.charAt(0).toUpperCase() + type.replaceAll('_', ' ').slice(1);
                                                            });
                                                            const filteredTypes = formattedTypes.filter(type => type !== "Point of interest" && type !== "Establishment");
                                                            if (filteredTypes.length > 0) {
                                                                competitorCat1 = filteredTypes[0];
                                                                // Find the next available category that is different from competitorCat1
                                                                competitorCat2 = filteredTypes.find(type => type !== competitorCat1) || t('competition.catNone');
                                                            }
                                                        }
                                                    } else if (competitorGmbInfo && competitorGmbInfo.types && competitorGmbInfo.types.length > 0) {
                                                        const formattedTypes = competitorGmbInfo.types.map(type => {
                                                            return type.charAt(0).toUpperCase() + type.replaceAll('_', ' ').slice(1);
                                                        });
                                                        // Filter out unwanted categories
                                                        const filteredTypes = formattedTypes.filter(type => type !== "Point of interest" && type !== "Establishment");
                                                        // Assign filtered types to categories
                                                        if (filteredTypes.length > 0) {
                                                            competitorCat1 = filteredTypes[0];
                                                            // Find the next available category that is different from competitorCat1
                                                            competitorCat2 = filteredTypes.find(type => type !== competitorCat1) || t('competition.catNone');
                                                        }
                                                    }

                                                    return (
                                                        <tr key={key}>
                                                            <td className={"competitor-number"}>{key + 2}</td>
                                                            {
                                                                competitorGmbInfo && competitorGmbInfo.websiteUri && competitorGmbInfo.displayName ?
                                                                    <td className={"competitor-link"}>{competitorGmbInfo.displayName.text}
                                                                        <a
                                                                            href={competitorGmbInfo.websiteUri}
                                                                            target="_blank"
                                                                            rel="noreferrer"><FiExternalLink
                                                                            className={"external-link-icon"}/></a>
                                                                    </td> :
                                                                    competitorGmbInfo && competitorGmbInfo.displayName ?
                                                                        <td>{competitorGmbInfo.displayName.text}</td> :
                                                                        <td>{t('competition.noName')}</td>

                                                            }
                                                            {
                                                                competitorGmbInfo && competitorGmbInfo.distance ?
                                                                    <td>{competitorGmbInfo.distance}m</td> :
                                                                    <td>0m</td>
                                                            }
                                                            {
                                                                competitorsOrganicSearch && competitorsOrganicSearch.length && competitorsOrganicSearch[key]['Organic Traffic'] ?
                                                                    <td>{competitorsOrganicSearch[key]['Organic Traffic']}</td> :
                                                                    <td>0</td>
                                                            }
                                                            {
                                                                competitorsBacklinksOverview && competitorsBacklinksOverview[key].error ?
                                                                    <>
                                                                        <td>0</td>
                                                                        <td>0</td>
                                                                    </> :
                                                                    competitorsBacklinksOverview && competitorsBacklinksOverview.length && competitorsBacklinksOverview[key][0].ascore && competitorsBacklinksOverview[key][0].total ?
                                                                        <>
                                                                            <td>{competitorsBacklinksOverview[key][0].ascore}</td>
                                                                            <td>{competitorsBacklinksOverview[key][0].total}</td>
                                                                        </> :
                                                                        competitorsBacklinksOverview && competitorsBacklinksOverview[key][0].ascore ?
                                                                            <>
                                                                                <td>{competitorsBacklinksOverview[key][0].ascore}</td>
                                                                                <td>0</td>
                                                                            </> :
                                                                            <>
                                                                                <td>0</td>
                                                                                <td>{competitorsBacklinksOverview[key][0].total}</td>
                                                                            </>

                                                            }
                                                            <td>{competitorCat1}</td>
                                                            <td>{competitorCat2}</td>
                                                            {competitorGmbInfo && competitorGmbInfo.userRatingCount ?
                                                                <td>{competitorGmbInfo.userRatingCount}</td> :
                                                                <td>0</td>
                                                            }
                                                            {competitorGmbInfo && competitorGmbInfo.rating ?
                                                                <td>{competitorGmbInfo.rating}/5</td> :
                                                                <td>0/5</td>
                                                            }
                                                            {
                                                                competitorsPagespeed && competitorsPagespeed.length ?
                                                                    <td>{competitorsPagespeed[key]}</td> :
                                                                    <td>0</td>
                                                            }
                                                            {
                                                                competitorsBlogPosts && competitorsBlogPosts.length ?
                                                                    <td>{competitorsBlogPosts[key]}</td> :
                                                                    <td>0</td>
                                                            }
                                                            {
                                                                competitorGmbInfo.photos && competitorGmbInfo.photos.length ?
                                                                    competitorGmbInfo.photos.length === 10 ?
                                                                        <td>10+</td> :
                                                                        <td>{competitorGmbInfo.photos.length}</td> :
                                                                    <td>0</td>
                                                            }
                                                            {competitorsStructuredData && competitorsStructuredData.length ?
                                                                <td>{getStructuredData(competitorsStructuredData[key].status)}</td> :
                                                                <td>{t('competition.structuredDataStatus')}</td>
                                                            }

                                                        </tr>
                                                    )
                                                }) :
                                                !competitorsDfs && competitorsDfs.length === 0 ?
                                                    <p>{t('competition.noResult')}</p> :
                                                    competitorsDfs.map((competitorGmbInfo, key) => {

                                                        let competitorCat1 = t('competition.catNone');
                                                        let competitorCat2 = t('competition.catNone');

                                                        if (competitorGmbInfo && competitorGmbInfo.primaryTypeDisplayName) {
                                                            competitorCat1 = competitorGmbInfo.primaryTypeDisplayName;
                                                            if (competitorGmbInfo.category2) {
                                                                competitorCat2 = competitorGmbInfo.category2;
                                                            }
                                                        }

                                                        return (
                                                            <tr key={key}>
                                                                <td className={"competitor-number"}>{key + 2}</td>
                                                                {
                                                                    competitorGmbInfo && competitorGmbInfo.websiteUri && competitorGmbInfo.displayName ?
                                                                        <td className={"competitor-link"}>{competitorGmbInfo.displayName}
                                                                            <a
                                                                                href={competitorGmbInfo.websiteUri}
                                                                                target="_blank"
                                                                                rel="noreferrer"><FiExternalLink
                                                                                className={"external-link-icon"}/></a>
                                                                        </td> :
                                                                        competitorGmbInfo && competitorGmbInfo.displayName ?
                                                                            <td>{competitorGmbInfo.displayName}</td> :
                                                                            <td>{t('competition.noName')}</td>

                                                                }
                                                                {
                                                                    competitorGmbInfo && competitorGmbInfo.distance ?
                                                                        <td>{competitorGmbInfo.distance}m</td> :
                                                                        <td>0m</td>
                                                                }
                                                                {
                                                                    competitorsDomainOverview[key].error ?
                                                                        <td>0</td> :
                                                                        <td>{competitorsDomainOverview[key][0]['Organic Traffic']}</td>
                                                                }
                                                                {
                                                                    competitorsBacklinksOverview && competitorsBacklinksOverview[key].error ?
                                                                        <>
                                                                            <td>0</td>
                                                                            <td>0</td>
                                                                        </> :
                                                                        competitorsBacklinksOverview && competitorsBacklinksOverview.length && competitorsBacklinksOverview[key][0].ascore && competitorsBacklinksOverview[key][0].total ?
                                                                            <>
                                                                                <td>{competitorsBacklinksOverview[key][0].ascore}</td>
                                                                                <td>{competitorsBacklinksOverview[key][0].total}</td>
                                                                            </> :
                                                                            competitorsBacklinksOverview && competitorsBacklinksOverview[key][0].ascore ?
                                                                                <>
                                                                                    <td>{competitorsBacklinksOverview[key][0].ascore}</td>
                                                                                    <td>0</td>
                                                                                </> :
                                                                                <>
                                                                                    <td>0</td>
                                                                                    <td>{competitorsBacklinksOverview[key][0].total}</td>
                                                                                </>

                                                                }
                                                                <td>{competitorCat1}</td>
                                                                <td>{competitorCat2}</td>
                                                                {competitorGmbInfo && competitorGmbInfo.userRatingCount ?
                                                                    <td>{competitorGmbInfo.userRatingCount}</td> :
                                                                    <td>0</td>
                                                                }
                                                                {competitorGmbInfo && competitorGmbInfo.rating ?
                                                                    <td>{competitorGmbInfo.rating}/5</td> :
                                                                    <td>0/5</td>
                                                                }
                                                                {
                                                                    competitorsPagespeed && competitorsPagespeed.length ?
                                                                        <td>{competitorsPagespeed[key]}</td> :
                                                                        <td>0</td>
                                                                }
                                                                {
                                                                    competitorsBlogPosts && competitorsBlogPosts.length ?
                                                                        <td>{competitorsBlogPosts[key]}</td> :
                                                                        <td>0</td>
                                                                }
                                                                {
                                                                    competitorGmbInfo.photos ?
                                                                        <td>{competitorGmbInfo.photos}</td> :
                                                                        <td>0</td>
                                                                }
                                                                {competitorsStructuredData && competitorsStructuredData.length ?
                                                                    <td>{getStructuredData(competitorsStructuredData[key].status)}</td> :
                                                                    <td>{t('competition.structuredDataStatus')}</td>
                                                                }

                                                            </tr>
                                                        )
                                                    })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                {isIOS() && (
                                    <div className={"ios-buttons"}>
                                        <FiChevronLeft size={32} color={"#111"}
                                                       onClick={() => handleScroll('left')}
                                                       disabled={scrollLeft === 0}/>
                                        <FiChevronRight size={32} color={"#111"}
                                                        onClick={() => handleScroll('right')}/>
                                    </div>
                                )}
                            </div>
                }
            </div>
        </div>
    )

}

export default Competitors;