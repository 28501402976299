import '../App.css';
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";

function Form() {
    const {t} = useTranslation("common");

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute(
            'src',
            'https://assets.calendly.com/assets/external/widget.js'
        );
        head.appendChild(script);
    }, []);
    return (
        <div className="grid-container form">
            <div className="wrapper grid-item12">
                <div className={"title-wrapper"}>
                    <h3 className={"title"}>{t('form.title')}</h3>
                </div>
                <div>
                    <p>{t('form.text')}</p>
                </div>
                <div
                    className="calendly-inline-widget"
                    data-url={"https://calendly.com/omnihyper_pt/hyperzone?show_event_type_details=1&primary_color=FF7110"}
                    style={{ minWidth: '620px', height: '660px' }}
                />
            </div>
        </div>
    )

}

export default Form;