import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

function LinearProgressWithLabel(props) {

    return (
        <div className={"container-progress"}>
            <div className={"progress-bar"}>
                <LinearProgress sx={{height: 10, borderRadius: 5, [`&.${linearProgressClasses.colorPrimary}`]: {backgroundColor: '#eeeeee'},[`& .${linearProgressClasses.bar}`]:{borderRadius: 5,backgroundColor: '#FF7110'}}}  variant="determinate" {...props} />
            </div>
            <div className={"progress-text"}>
                <p color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</p>
            </div>
        </div>
    );
}

export default LinearProgressWithLabel;