import '../App.css';
import React from "react";
import {Gauge, gaugeClasses} from '@mui/x-charts/Gauge';
import {axisClasses, BarChart} from "@mui/x-charts";
import {IoMdInformationCircleOutline} from "react-icons/io";
import {Tooltip} from "@mui/material";
import {useTranslation} from "react-i18next";

function Overview({
                      gmb,
                      name,
                      cat1,
                      cat2,
                      blogPosts,
                      googleReviewsPercentage,
                      backlinkPercentage,
                      websitePagesPercentage,
                      technicalCompliancePercentage,
                      pagespeedPercentage,
                      overallBenchmark,
                      benchmarkSector,
                      socialMediaPercentage,
                      localLinksPercentage
                  }) {

    const {t} = useTranslation("common");

    if (backlinkPercentage > 100) {
        backlinkPercentage = 100
    } else if (backlinkPercentage < 0) {
        backlinkPercentage = 0
    }

    if (websitePagesPercentage > 100) {
        websitePagesPercentage = 100
    } else if (websitePagesPercentage < 0) {
        websitePagesPercentage = 0
    }

    if (technicalCompliancePercentage > 100) {
        technicalCompliancePercentage = 100
    } else if (technicalCompliancePercentage < 0) {
        technicalCompliancePercentage = 0
    }

    if (pagespeedPercentage > 100) {
        pagespeedPercentage = 100
    } else if (pagespeedPercentage < 0) {
        pagespeedPercentage = 0
    }

    if (overallBenchmark > 100) {
        overallBenchmark = 100
    } else if (overallBenchmark < 0) {
        overallBenchmark = 0
    }

    if (socialMediaPercentage > 100) {
        socialMediaPercentage = 100
    } else if (socialMediaPercentage < 0) {
        socialMediaPercentage = 0
    }

    if (localLinksPercentage > 100) {
        localLinksPercentage = 100
    } else if (localLinksPercentage < 0) {
        localLinksPercentage = 0
    }

    return (
        <div className="grid-container overview">
            <div className="wrapper grid-item1">
                <div className={"title-wrapper"}>
                    <h3 className="title">{t('overview.titleOverview')}</h3>
                    <div className={"desktop"}>
                        <Tooltip title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('overview.titleOverview')}</p>
                                    <p>{t('overview.overviewInfo1')}</p>
                                    <p className={"tip-title"}>{t('overview.overviewInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                    <div className={"tablet-mobile"}>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={6000} title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('overview.titleOverview')}</p>
                                    <p>{t('overview.overviewInfo1')}</p>
                                    <p className={"tip-title"}>{t('overview.overviewInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                </div>
                <div>
                    <div className={"desktop"}>
                        <BarChart
                            sx={{
                                [`.${axisClasses.tickLabel}`]: {
                                    fontFamily: 'Montserrat, sans serif',
                                },
                            }}
                            xAxis={[{
                                scaleType: 'band',
                                categoryGapRatio: 0.6,
                                data: [t('overview.barChartCat1'), t('overview.barChartCat2'), t('overview.barChartCat3'), t('overview.barChartCat4'), t('overview.barChartCat5'), t('overview.barChartCat6'), 'Social Links']
                            }]}
                            series={[{
                                data: [backlinkPercentage, websitePagesPercentage, technicalCompliancePercentage, pagespeedPercentage, googleReviewsPercentage, socialMediaPercentage, localLinksPercentage],
                                color: '#FF7110'
                            }]}
                            width={900}
                            height={500}
                            yAxis={[{label: '%'}]}
                        />
                    </div>
                    <div className={"tablet"}>
                        <BarChart
                            sx={{
                                [`.${axisClasses.tickLabel}`]: {
                                    fontFamily: 'Montserrat, sans serif',
                                },
                            }}
                            xAxis={[{
                                scaleType: 'band',
                                categoryGapRatio: 0.6,
                                data: [t('overview.barChartCat1'), t('overview.barChartCat2'), t('overview.barChartCat3'), t('overview.barChartCat4'), t('overview.barChartCat5'), t('overview.barChartCat6'), 'Social Links']
                            }]}
                            series={[{
                                data: [backlinkPercentage, websitePagesPercentage, technicalCompliancePercentage, pagespeedPercentage, googleReviewsPercentage, socialMediaPercentage, localLinksPercentage],
                                color: '#FF7110'
                            }]}
                            width={700}
                            height={500}
                            yAxis={[{label: '%'}]}
                        />
                    </div>
                    <div className={"mobile"}>
                        <BarChart
                            sx={{
                                [`.${axisClasses.tickLabel}`]: {
                                    fontFamily: 'Montserrat, sans serif',
                                },
                            }}
                            xAxis={[{
                                scaleType: 'band',
                                categoryGapRatio: 0.4,
                                data: [t('overview.barChartCat1'), t('overview.barChartCat2'), t('overview.barChartCat3'), t('overview.barChartCat4'), t('overview.barChartCat5'), t('overview.barChartCat6'), t('overview.barChartCat7')]
                            }]}
                            series={[{
                                data: [backlinkPercentage, websitePagesPercentage, technicalCompliancePercentage, pagespeedPercentage, googleReviewsPercentage, socialMediaPercentage, localLinksPercentage],
                                color: '#FF7110'
                            }]}
                            width={310}
                            height={400}
                            yAxis={[{label: '%'}]}
                        />
                    </div>
                    <div className={"chart-def"}>
                        <div>
                            <p>{t('overview.barChartCat1')}</p>
                            <p className={"chart-data"}>{backlinkPercentage}%</p>
                        </div>
                        <div>
                            <p>{t('overview.barChartCat2')}</p>
                            <p className={"chart-data"}>{websitePagesPercentage}%</p>
                        </div>
                        <div>
                            <p>{t('overview.barChartCat3')}</p>
                            <p className={"chart-data"}>{technicalCompliancePercentage}%</p>
                        </div>
                        <div>
                            <p>{t('overview.barChartCat4')}</p>
                            <p className={"chart-data"}>{pagespeedPercentage}%</p>
                        </div>
                    </div>
                    <div className={"chart-def"}>
                        <div>
                            <p>{t('overview.barChartCat5')}</p>
                            <p className={"chart-data"}>{googleReviewsPercentage}%</p>
                        </div>
                        <div>
                            <p>{t('overview.barChartCat6')}</p>
                            <p className={"chart-data"}>{socialMediaPercentage}%</p>
                        </div>
                        <div>
                            <p>{t('overview.barChartCat7')}</p>
                            <p className={"chart-data"}>{localLinksPercentage}%</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="grid-item2 wrapper">
                <div className={"title-wrapper"}>
                    <h3 className="title">{t('overview.titleBenchmark')}</h3>
                    <div className={"desktop"}>
                        <Tooltip title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('overview.titleBenchmark')}</p>
                                    <p>{t('overview.infoBenchmark1')}</p>
                                    <ul>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleBenchmark1')}</span>
                                            {t('overview.liBenchmark1')}
                                        </li>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleBenchmark2')}</span>
                                            {t('overview.liBenchmark2')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('overview.infoBenchmark2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                    <div className={"tablet-mobile"}>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={6000} title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('overview.titleBenchmark')}</p>
                                    <p>{t('overview.infoBenchmark1')}</p>
                                    <ul>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleBenchmark1')}</span>
                                            {t('overview.liBenchmark1')}
                                        </li>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleBenchmark2')}</span>
                                            {t('overview.liBenchmark2')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('overview.infoBenchmark2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                </div>
                <div className={"gauge"}>
                    <div className={"gauge-wrapper"}>
                        <Gauge width={100} height={100} value={overallBenchmark}
                               text={({value, valueMax}) => `${value}%`} sx={{
                            [`& .${gaugeClasses.valueText}`]: {fontWeight: 'bold', fontStyle: 'italic'},
                            [`& .${gaugeClasses.valueArc}`]: {fill: '#FF5717'},
                        }}/>
                        <p className={"gauge-text"}>{gmb.title}</p>
                    </div>
                    {
                        cat1 !== "none" ?
                            <div className={"gauge-wrapper"}>
                                <Gauge width={100} height={100} value={benchmarkSector}
                                       text={({value, valueMax}) => `${value}%`} sx={{
                                    [`& .${gaugeClasses.valueText}`]: {fontWeight: 'bold', fontStyle: 'italic'},
                                    [`& .${gaugeClasses.valueArc}`]: {fill: '#FF9623',}
                                }}/>
                                <p className={"gauge-text"}>{t('overview.gaugeTextSector', {cat1: cat1})}</p>
                            </div> :
                            null
                    }
                </div>
            </div>
            <div className="grid-item3 wrapper">
                <div className={"title-wrapper"}>
                    <h3 className="title">{t('overview.citationTitle')}</h3>
                    <div className={"desktop"}>
                        <Tooltip title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('overview.citationTitle')}</p>
                                    <p>{t('overview.citationInfo1')}</p>
                                    <ul>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleCitation1')}</span>
                                            {t('overview.liCitation1')}
                                        </li>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleCitation2')}</span>
                                            {t('overview.liCitation2')}
                                        </li>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleCitation3')}</span>
                                            {t('overview.liCitation3')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('overview.citationInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                    <div className={"tablet-mobile"}>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={6000} title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('overview.citationTitle')}</p>
                                    <p>{t('overview.citationInfo1')}</p>
                                    <ul>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleCitation1')}</span>
                                            {t('overview.liCitation1')}
                                        </li>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleCitation2')}</span>
                                            {t('overview.liCitation2')}
                                        </li>
                                        <li>
                                            <span className={"tip-title"}>{t('overview.tipTitleCitation3')}</span>
                                            {t('overview.liCitation3')}
                                        </li>
                                    </ul>
                                    <p className={"tip-title"}>{t('overview.citationInfo2')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                </div>
                {!gmb ?
                    <p>No results found</p>
                    :
                    <div>
                        {
                            gmb.title ?
                                <>
                                    <h4>{t('overview.citationName')}</h4>
                                    <p>{gmb.title}</p>
                                </> :
                                <>
                                    <h4>{t('overview.citationName')}</h4>
                                    <p>{name}</p>
                                </>
                        }
                        {
                            gmb.address ?
                                <>
                                    <h4>{t('overview.citationAddress')}</h4>
                                    <p>{gmb.address}</p>
                                </> :
                                <>
                                    <h4>{t('overview.citationAddress')}</h4>
                                    <p>{t('overview.citationNotFound')}</p>
                                </>
                        }
                        {
                            gmb.phone ?
                                <>
                                    <h4>{t('overview.citationPhone')}</h4>
                                    <p>{gmb.phone}</p>
                                </> :
                                <>
                                    <h4>{t('overview.citationPhone')}</h4>
                                    <p>{t('overview.citationNotFound')}</p>
                                </>
                        }
                        <h4>{t('overview.citationPrimaryCat')}</h4>
                        <p>{cat1}</p>
                        <h4>{t('overview.citationSecondaryCat')}</h4>
                        <p>{cat2}</p>
                        {
                            gmb.rating ?
                                <>
                                    <h4>{t('overview.citationReviews')}</h4>
                                    <p>{t('overview.citationReviewsText', {
                                        rating: gmb.rating.value,
                                        ratingCount: gmb.rating.votes_count
                                    })}</p>
                                </> :
                                <>
                                    <h4>{t('overview.citationReviews')}</h4>
                                    <p>{t('overview.citationNoReviews')}</p>
                                </>
                        }
                        {
                            blogPosts ?
                                <>
                                    <h4>{t('overview.citationPosts')}</h4>
                                    <p>{blogPosts}</p>
                                </> :
                                <>
                                    <h4>{t('overview.citationPosts')}</h4>
                                    <p>0</p>
                                </>
                        }
                        {
                            gmb.total_photos ?
                                <>
                                    <h4>{t('overview.citationImages')}</h4>
                                    <p>{gmb.total_photos}</p>
                                </> :
                                <>
                                    <h4>{t('overview.citationImages')}</h4>
                                    <p>0</p>
                                </>
                        }
                    </div>
                }
            </div>
        </div>
    )

}

export default Overview;