import '../App.css';
import React from "react";
import {Tooltip} from "@mui/material";
import {IoMdInformationCircleOutline} from "react-icons/io";
import ReactMarkdown from 'react-markdown';
import {useTranslation} from "react-i18next";

function AIRecommendation({aIRecommendation}) {
    const {t} = useTranslation("common");
    return (
        <div className="grid-container ai-recommendation">
            <div className="wrapper grid-item11">
                <div className={"title-wrapper"}>
                    <h3 className={"title"}>{t('aiRecommendation.title')}</h3>
                    <div className={"desktop"}>
                        <Tooltip title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('aiRecommendation.title')}</p>
                                    <p>{t('aiRecommendation.info1')}</p>
                                    <p>{t('aiRecommendation.info2')}</p>
                                    <p>{t('aiRecommendation.info3')}</p>
                                    <p>{t('aiRecommendation.info4')}</p>
                                    <p className={"tip-title"}>{t('aiRecommendation.info5')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                    <div className={"tablet-mobile"}>
                        <Tooltip enterTouchDelay={0} leaveTouchDelay={6000} title={
                            <React.Fragment>
                                <div className={"tip-wrapper"}>
                                    <p className={"tip-title"}>{t('aiRecommendation.title')}</p>
                                    <p>{t('aiRecommendation.info1')}</p>
                                    <p>{t('aiRecommendation.info2')}</p>
                                    <p>{t('aiRecommendation.info3')}</p>
                                    <p>{t('aiRecommendation.info4')}</p>
                                    <p className={"tip-title"}>{t('aiRecommendation.info5')}</p>
                                </div>
                            </React.Fragment>
                        } placement="top">
                            <div className={"icon-wrapper"}><IoMdInformationCircleOutline/></div>
                        </Tooltip>
                    </div>
                </div>
                <div>
                    {aIRecommendation ? (
                        <ReactMarkdown className={"content"}>{aIRecommendation}</ReactMarkdown>
                    ) : (
                        <p>{t('aiRecommendation.info2')}</p>
                    )}
                </div>
            </div>
        </div>
    )

}

export default AIRecommendation;