import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";

const locales = {
    en: 'English',
    fr: 'Français',
    es: 'Español'
};

function getFlagSrc(language) {
    let countryCode;
    if (language === 'en') countryCode = 'GB'
    if (language === 'fr') countryCode = 'FR'
    if (language === 'es') countryCode = 'ES'
    return `https://flagsapi.com/${countryCode}/flat/64.png`;
}

export const LanguageSelector = () => {
    const [t, i18n] = useTranslation('common');
    const [selectedLocale, setSelectedLocale] = useState(i18n.language);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleLocaleChange = (locale) => {
        i18n.changeLanguage(locale);
        setSelectedLocale(locale);
        setDropdownOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="dropdown" tabIndex="0" ref={dropdownRef}>
            <button id="dropdown-btn" onClick={() => setDropdownOpen(!dropdownOpen)}>
                <img src={getFlagSrc(selectedLocale)} alt="Flag" />
                {locales[selectedLocale]}
                <span className="arrow-down"></span>
            </button>
            {dropdownOpen && (
                <ul className="dropdown-content" id="dropdown-content">
                    {Object.keys(locales).filter(locale => locale !== selectedLocale).map((locale) => (
                        <li key={locale} onMouseDown={() => handleLocaleChange(locale)}>
                            {locales[locale]}
                            <img src={getFlagSrc(locale)} alt="Flag" />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
/*
    return (
        <div>
            <span>{t('language-selector.label')} </span>
            <button onClick={() => i18n.changeLanguage('fr')}>{t('language-selector.languages.fr')}</button>
            &nbsp;
            <button onClick={() => i18n.changeLanguage('en')}>{t('language-selector.languages.en')}</button>
        </div>
    )

 */
}